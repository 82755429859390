/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
.blogItem .arrow-link:after {
  @apply text-yellow-300;
  @apply text-base;
  content: '🠖';
  transition: all 0.25s;
  opacity: 0;
  margin-left: -0.2em;
  color: #fbb03b;
}
.blogItem:hover .arrow-link {
  @apply text-yellow-300;
}
.blogItem:hover .arrow-link:after {
  opacity: 1;
  margin-left: 0.4em;
}
.blogItem:hover h3 {
  @apply text-yellow-300;
}
.blogItem:hover .blogItem-hover {
  @apply text-yellow-300;
}
.colored-list li::before {
  @apply text-yellow-300;
  content: '\2022';
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.input-error {
  @apply border border-red-500;
}
.yellow-link {
  color: #fbb03b;
  position: relative;
}
.yellow-link:after {
  content: '';
  position: absolute;
  transition: all 0.25s;
  opacity: 0;
  height: 2px;
  width: 0%;
  bottom: 0;
  left: 0;
  background: #fbb03b;
}
.yellow-link:hover:after,
.yellow-link:focus:after {
  opacity: 1;
  width: 100%;
}

@tailwind utilities;

.hero {
  width: 100%;
  color: #333;
}

.title {
  margin: 0;
  width: 100%;
  padding-top: 80px;
  line-height: 1.15;
  font-size: 48px;
}

.title,
.description {
  text-align: center;
}

.family-inter {
  font-family: Inter;
}
